import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    Markdown_guide: westack {
      tool(id: "markdown-guide") {
        ...ToolInfo
      }
    }
    MerLot: westack {
      tool(id: "merlot") {
        ...ToolInfo
      }
    }
    MDX: westack {
      tool(id: "mdx") {
        ...ToolInfo
      }
    }
    WeChat_Format: westack {
      tool(id: "wechat-format") {
        ...ToolInfo
      }
    }
    Taio: westack {
      tool(id: "taio") {
        ...ToolInfo
      }
    }
    Gitbook: westack {
      tool(id: "gitbook") {
        ...ToolInfo
      }
    }
    Typora: westack {
      tool(id: "typora") {
        ...ToolInfo
      }
    }
    Remark: westack {
      tool(id: "remark") {
        ...ToolInfo
      }
    }
    Gridea: westack {
      tool(id: "gridea") {
        ...ToolInfo
      }
    }
    Obisidian: westack {
      tool(id: "obisidian") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.Markdown_guide.tool} mdxType="Tool" />
    <Tool tool={props.data.MerLot.tool} mdxType="Tool" />
    <Tool tool={props.data.MDX.tool} mdxType="Tool" />
    <Tool tool={props.data.WeChat_Format.tool} mdxType="Tool" />
    <Tool tool={props.data.Taio.tool} mdxType="Tool" />
    <Tool tool={props.data.Gitbook.tool} mdxType="Tool" />
    <Tool tool={props.data.Typora.tool} mdxType="Tool" />
    <Tool tool={props.data.Remark.tool} mdxType="Tool" />
    <Tool tool={props.data.Gridea.tool} mdxType="Tool" />
    <Tool tool={props.data.Obisidian.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      